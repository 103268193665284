import { action, computed, makeObservable, observable } from 'mobx';

import { ApiClient } from '../../apiClient/ApiClient';
import { ErrorReporter } from '../../errorHandling/ErrorReporter';
import { TranslationKey } from '../../i18n/i18n';
import { ExperimentsStore } from '../../stores/ExperimentsStore';
import { Category } from '../../web-api/domain/category';
import { ConfigStore } from './ConfigStore';

export interface CategoriesStoreState {
  bookName: string | null;
  categories: Category[] | null;
  intentCategories: Category[] | null;
}

export interface CategoryGroup {
  key: string;
  shortDisplayNameKey?: TranslationKey;
  displayNameKey: TranslationKey;
  categories: (Category | undefined)[];
}

export class CategoriesStore {
  private apiClient: ApiClient;
  private errorReporter: ErrorReporter;
  private readonly experimentsStore: ExperimentsStore;
  private readonly configStore: ConfigStore;
  public bookName: string | null = null;
  public categories: Category[] | null = null;
  public intentCategories: Category[] | null = null;
  public networkError: Error | null = null;

  constructor(
    {
      apiClient,
      errorReporter,
      configStore,
      experimentsStore,
    }: {
      apiClient: ApiClient;
      errorReporter: ErrorReporter;
      configStore: ConfigStore;
      experimentsStore: ExperimentsStore;
    },
    initialState?: CategoriesStoreState,
  ) {
    makeObservable<CategoriesStore, 'allCategoriesById' | 'allCategoriesBySlug' | 'setNetworkError' | 'setCategories'>(
      this,
      {
        networkError: observable,
        allCategoriesBySlug: computed,
        allCategoriesById: computed,
        sortCategories: computed,
        getCategoryBySlug: action,
        initialFetch: action,
        setNetworkError: action,
        setCategories: action,
      },
    );

    this.apiClient = apiClient;
    this.errorReporter = errorReporter;
    this.configStore = configStore;
    this.experimentsStore = experimentsStore;
    if (initialState) {
      this.setCategories(initialState.bookName, initialState.categories);
      this.intentCategories = initialState.intentCategories;
    }
  }

  private get allCategoriesBySlug(): Map<string, Category> {
    const categories = this.categories || [];
    const allCategories = [...categories];

    categories
      .filter(({ subCategories }) => subCategories)
      .forEach(({ subCategories }) => allCategories.push(...subCategories));

    return new Map(allCategories.filter((item) => item.type !== 'none').map((item) => [item.name, item]));
  }

  private get allCategoriesById(): Map<string, Category> {
    const categories = this.categories || [];
    const allCategories = [...categories];

    categories
      .filter(({ subCategories }) => subCategories)
      .forEach(({ subCategories }) => allCategories.push(...subCategories));

    return new Map(allCategories.map((item) => [item.categoryId, item]));
  }

  public getParentCategoryBySlug(slug: string): Category | undefined {
    return (this.categories ?? []).find(
      ({ subCategories }) => subCategories && subCategories.some(({ name }) => name === slug),
    );
  }

  public isCategoriesRelated(parentSlug: string, subCategorySlug: string): boolean {
    return this.getParentCategoryBySlug(subCategorySlug)?.name === parentSlug;
  }

  public get sortCategories(): Category[] {
    return Array.from(this.allCategoriesBySlug.values()).filter(({ type }) => type === 'sort');
  }

  public get categoryGroups(): CategoryGroup[] {
    const categoryGroups: CategoryGroup[] = [
      {
        key: 'businessAndServices',
        displayNameKey: 'categoriesMenu.categoryGroup.businessAndServices',
        shortDisplayNameKey: 'categoriesMenu.categoryGroup.business',
        categories: [
          'business',
          'one-page',
          'industrial',
          'health-wellness',
          'beauty-and-hair',
          'fashion-and-style',
          'travel-tourism',
          'restaurants-food',
          'professional-cv',
        ].map(this.getCategoryBySlug),
      },
      {
        key: 'store',
        displayNameKey: 'categoriesMenu.categoryGroup.store',
        categories: ['online-store'].map(this.getCategoryBySlug),
      },
      {
        key: 'creative',
        displayNameKey: 'categoriesMenu.categoryGroup.creative',
        categories: ['photography', 'design', 'music', 'creative-arts', 'portfolio-cv', 'video'].map(
          this.getCategoryBySlug,
        ),
      },
      {
        key: 'community',
        displayNameKey: 'categoriesMenu.categoryGroup.community',
        categories: ['education', 'communities', 'events'].map(this.getCategoryBySlug),
      },
      {
        key: 'blog',
        displayNameKey: 'categoriesMenu.categoryGroup.blog',
        categories: ['blog'].map(this.getCategoryBySlug),
      },
    ];

    return categoryGroups
      .filter(Boolean)
      .map(({ categories, ...rest }) => ({
        ...rest,
        categories: categories.filter((category) => this.categories?.includes(category)),
      }))
      .filter(({ categories }) => categories.length > 0);
  }

  public getIntentCategoryBySlug = (slug: string): Category | undefined =>
    this.intentCategories.flatMap((ic) => ic.subCategories).find((ic) => ic.name === slug);

  public getCategoryBySlug = (slug: string): Category | undefined => this.allCategoriesBySlug.get(slug);

  public getCategoryById = (categoryId: string): Category | undefined => this.allCategoriesById.get(categoryId);

  public async initialFetch(): Promise<void> {
    try {
      const { bookName, items, intentCategories } = await this.apiClient.fetchCategories();
      this.setCategories(bookName, items);
      this.intentCategories = intentCategories;
    } catch (error) {
      this.setNetworkError(error);
      this.errorReporter.reportError(error);
      this.setCategories(null, null);
      this.intentCategories = null;
    }
  }

  private setNetworkError(error: Error) {
    this.networkError = error;
  }

  private setCategories(bookName: string | null, categories: Category[] | null) {
    this.bookName = bookName;
    this.categories = categories;
  }

  serialize(): CategoriesStoreState {
    return {
      bookName: this.bookName,
      categories: this.categories,
      intentCategories: this.intentCategories,
    };
  }
}
