import { BreadcrumbList, ListItem } from 'schema-dts';
import { helmetJsonLdProp } from 'react-schemaorg';
import { RoutingStore } from '../../stores/RoutingStore';
import { BaseURLStore } from '../../stores/BaseURLStore';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { TFunction } from '../../../i18n/i18n';
import { ConfigStore } from '../../stores/ConfigStore';
import { getBreadcrumbs } from '../../../utils/getBreadcrumbs';
import { ExperimentsStore } from '../../../stores/ExperimentsStore';

interface GenerateActiveCategoryUrlParams {
  routingStore: RoutingStore;
  categoriesStore: CategoriesStore;
  baseURLStore: BaseURLStore;
  t: TFunction;
}

function generateActiveCategoryListItemProps({
  routingStore,
  categoriesStore,
  baseURLStore,
}: GenerateActiveCategoryUrlParams): Pick<ListItem, 'item' | 'name'> | null {
  const { matchedRoute } = routingStore;
  const activeCategory =
    matchedRoute.routeName === 'category' || matchedRoute.routeName === 'subCategory'
      ? categoriesStore.getCategoryBySlug(matchedRoute.params.categorySlug)
      : null;

  if (!activeCategory) {
    return null;
  }

  return {
    item: baseURLStore.buildURL(routingStore.locationBuilders.category({ categorySlug: activeCategory.name })),
    name: activeCategory.displayName,
  };
}

function generateActiveSubCategoryListItemProps({
  routingStore,
  categoriesStore,
  baseURLStore,
}: GenerateActiveCategoryUrlParams): Pick<ListItem, 'item' | 'name'> | null {
  const { matchedRoute } = routingStore;
  const activeCategory =
    matchedRoute.routeName === 'category' || matchedRoute.routeName === 'subCategory'
      ? categoriesStore.getCategoryBySlug(matchedRoute.params.categorySlug)
      : null;

  const activeSubCategory =
    matchedRoute.routeName === 'subCategory'
      ? categoriesStore.getCategoryBySlug(matchedRoute.params.subCategorySlug)
      : undefined;

  if (!activeCategory || !activeSubCategory) {
    return null;
  }

  return {
    item: baseURLStore.buildURL(
      routingStore.locationBuilders.subCategory({
        categorySlug: activeCategory.name,
        subCategorySlug: activeSubCategory.name,
      }),
    ),
    name: activeSubCategory.displayName,
  };
}

const generateAllBreadcrumbsToMicroCategory = ({ routingStore, categoriesStore, baseURLStore }) => {
  const { activeMicroCategorySlug } = routingStore;

  if (!activeMicroCategorySlug) {
    return [];
  }

  const microCategories = categoriesStore.intentCategories.filter(({ subCategories }) =>
    subCategories.find(({ name }) => name === activeMicroCategorySlug),
  );

  const displayName = microCategories
    .flatMap(({ subCategories }) => subCategories)
    .find(({ name }) => name === activeMicroCategorySlug)?.displayName;

  const breadcrumbs = microCategories
    .map((microCategory) =>
      getBreadcrumbs({ categories: categoriesStore?.categories, destinationCategorySlug: microCategory?.name }),
    )
    .reduce((acc, curr) => (acc.length > curr.length ? acc : curr), []);

  if (!breadcrumbs?.length) {
    return [];
  }

  const [category, subCategory] = breadcrumbs;

  return [
    {
      item: baseURLStore.buildURL(routingStore.locationBuilders.category({ categorySlug: category.name })),
      name: category.displayName,
    },
    // case when micro category directly in category
    ...(subCategory
      ? [
          {
            item: baseURLStore.buildURL(
              routingStore.locationBuilders.subCategory({
                categorySlug: category.name,
                subCategorySlug: subCategory.name,
              }),
            ),
            name: subCategory.displayName,
          },
        ]
      : []),
    {
      item: baseURLStore.buildURL(
        routingStore.locationBuilders.microCategory({
          microCategorySlug: activeMicroCategorySlug,
        }),
      ),
      name: displayName,
    },
  ];
};

function buildHomePageURL(configStore: ConfigStore) {
  const { domain, currentLanguage } = configStore.config;
  const langDomain = `${currentLanguage === 'en' ? 'www' : currentLanguage}.${domain}`;
  return `https://${langDomain}`;
}

interface GenerateBreadcrumbListScriptPropsParams {
  routingStore: RoutingStore;
  baseURLStore: BaseURLStore;
  categoriesStore: CategoriesStore;
  configStore: ConfigStore;
  experimentsStore: ExperimentsStore;
  t: TFunction;
}

type BreadcrumbListScriptProps = ReturnType<typeof helmetJsonLdProp>;

export function generateBreadcrumbListScriptProps({
  t,
  baseURLStore,
  routingStore,
  categoriesStore,
  configStore,
  experimentsStore,
}: GenerateBreadcrumbListScriptPropsParams): BreadcrumbListScriptProps {
  const { activeMicroCategorySlug } = routingStore;
  const isMicroCategoryPage = Boolean(activeMicroCategorySlug);
  const isEnabledMicroCategories =
    experimentsStore.is('specs.funnel.MarketingTemplatesMicroCategories', 'true') &&
    experimentsStore.is('specs.funnel.TemplatesBulkandMicrocatsEN', 'true');

  return helmetJsonLdProp<BreadcrumbList>({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        name: t('breadcrumbs.wixcom'),
        item: buildHomePageURL(configStore),
      },
      {
        name: t('breadcrumbs.templates'),
        item: baseURLStore.buildURL(routingStore.locationBuilders.home({})),
      },
      ...(isMicroCategoryPage && isEnabledMicroCategories
        ? generateAllBreadcrumbsToMicroCategory({ routingStore, categoriesStore, baseURLStore })
        : [
            generateActiveCategoryListItemProps({ routingStore, categoriesStore, baseURLStore, t }),
            generateActiveSubCategoryListItemProps({ routingStore, categoriesStore, baseURLStore, t }),
          ]),
    ]
      .filter(Boolean)
      .map((params, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        ...params,
      })),
  });
}
