import cs from 'classnames';
import React, { useCallback } from 'react';
import { injectStoresV2 } from '../../../../../stores/injectStoresV2';
import { RoutingStore } from '../../../../../stores/RoutingStore';
import { RouteLocation } from '../../../../../stores/routes/createRoute';
import { BILoggerStore } from '../../../../../stores/BILoggerStore';
import { TagLink } from '../../../../TagLink/TagLink';
import { categoryTagDataHooks } from './CategoryTag.dataHooks';
import s from './CategoryTag.scss';

export interface CategoryProps {
  location: RouteLocation;
  routingStore: RoutingStore;
  slug: string;
  title: string;
  biLoggerStore: BILoggerStore;
  order: number;
  dataHook?: string;
  isActive?: boolean;
  isAll?: boolean;
  itemRef?: React.Ref<HTMLLIElement>;
  onClick?: () => void;
}

const CategoryTagCmp: React.FC<CategoryProps> = (props) => {
  const { location, routingStore, slug, title, biLoggerStore, dataHook, order, isActive, isAll, itemRef, onClick } =
    props;
  const dataHooks = cs(dataHook, categoryTagDataHooks.item({ active: isActive }));

  const sendBi = (): void => {
    const { activeSubCategorySlug, activeCategorySlug } = routingStore;

    if (activeCategorySlug === slug) {
      biLoggerStore.logCategorySelect({
        clickSource: 'tags',
        category: activeCategorySlug,
      });

      biLoggerStore.logSubCategoryClear({
        previous_value: activeSubCategorySlug,
        selected_value: activeCategorySlug,
      });
    } else if (isActive) {
      biLoggerStore.logSubCategoryRemove({
        previous_value: activeSubCategorySlug,
        selected_value: slug,
      });
    } else {
      biLoggerStore.logCategorySelect({
        clickSource: 'tags',
        category: activeCategorySlug,
        subCategory: slug,
      });

      biLoggerStore.logSubCategoryAdd({
        previous_value: activeSubCategorySlug,
        selected_value: slug,
      });
    }
  };

  const onHandleClick = useCallback(() => {
    onClick && onClick();
    sendBi();
  }, [onClick, sendBi]);

  return (
    <li data-hook={dataHooks} className={cs(s.item, { [s.allItem]: isAll })} ref={itemRef} style={{ order }}>
      <TagLink
        order={order}
        title={title}
        isActive={isActive}
        location={location}
        onClick={onHandleClick}
        dataHook={categoryTagDataHooks.seoLink()}
      />
    </li>
  );
};

export const CategoryTag = injectStoresV2('routingStore', 'biLoggerStore')(CategoryTagCmp);
