import React from 'react';
import { Helmet } from 'react-helmet-async';
import { RoutingStore } from '../../stores/RoutingStore';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { BaseURLStore } from '../../stores/BaseURLStore';
import { ConfigStore } from '../../stores/ConfigStore';
import { OverriddenCategoryNamesStore } from '../../../stores/OverriddenCategoryNamesStore';
import { TemplatesStore } from '../../stores/TemplatesStore';
import { injectStoresV2 } from '../../stores/injectStoresV2';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import { ExperimentsStore } from '../../../stores/ExperimentsStore';
import { generateBreadcrumbListScriptProps } from './generateBreadcrumbListScriptProps';
import { generateTitle } from './generateTitle';
import { generateDescription } from './generateDescription';

export interface HtmlHeadProps extends WithTranslations {
  categoriesStore: CategoriesStore;
  routingStore: RoutingStore;
  baseURLStore: BaseURLStore;
  templatesStore: TemplatesStore;
  configStore: ConfigStore;
  experimentsStore: ExperimentsStore;
  overriddenCategoryNamesStore: OverriddenCategoryNamesStore;
}

const HtmlHeadCmp: React.FC<HtmlHeadProps> = ({
  routingStore,
  baseURLStore,
  configStore,
  overriddenCategoryNamesStore,
  templatesStore,
  categoriesStore,
  experimentsStore,
  t,
}) => {
  const { alternateCanonicalLink, nextPageLocation, previousPageLocation } = routingStore;
  const {
    config: { currentLanguage, supportedLanguages },
  } = configStore;
  const titleAndDescriptionParams = {
    routingStore,
    categoriesStore,
    overriddenCategoryNamesStore,
    t,
  };
  const title = generateTitle(titleAndDescriptionParams);
  const description = generateDescription(titleAndDescriptionParams);
  const nextPageExists = templatesStore.hasMore;
  const canonicalLink = baseURLStore.buildURL({
    ...routingStore.location,
    search: null,
  });
  const breadcrumbListScriptProps = generateBreadcrumbListScriptProps({
    t,
    baseURLStore,
    routingStore,
    categoriesStore,
    configStore,
    experimentsStore,
  });
  const isSearch = routingStore.matchedRoute?.routeName === 'search';
  const isMicroCategory = routingStore.matchedRoute?.routeName === 'microCategory';
  const isSort = !!routingStore.activeSortCategorySlug;
  const isNoIndex = isSearch || isSort;

  return (
    <Helmet script={[breadcrumbListScriptProps]}>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@wix" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />

      <meta name="og:type" content="website" />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:url" content={canonicalLink} />

      {!isNoIndex && <link rel="canonical" href={canonicalLink} />}

      {previousPageLocation && <link rel="prev" href={baseURLStore.buildURL(previousPageLocation)} />}
      {nextPageLocation && nextPageExists && <link rel="next" href={baseURLStore.buildURL(nextPageLocation)} />}

      {isNoIndex ? (
        <meta name="robots" content="noindex" />
      ) : (
        [
          <link
            rel="alternate"
            key="x-default"
            hrefLang="x-default"
            href={currentLanguage === 'en' ? canonicalLink : baseURLStore.buildLangURL(alternateCanonicalLink, 'en')}
          />,
          <link rel="alternate" key={currentLanguage} hrefLang={currentLanguage} href={canonicalLink} />,
          // micro cats only en right now
          ...(isMicroCategory
            ? []
            : supportedLanguages.map(
                (lang) =>
                  currentLanguage !== lang && (
                    <link
                      rel="alternate"
                      key={lang}
                      hrefLang={lang}
                      href={baseURLStore.buildLangURL(alternateCanonicalLink, lang)}
                    />
                  ),
              )),
        ]
      )}
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Helmet>
  );
};

export const HtmlHead = withTranslations(
  injectStoresV2(
    'routingStore',
    'baseURLStore',
    'categoriesStore',
    'configStore',
    'overriddenCategoryNamesStore',
    'templatesStore',
    'experimentsStore',
  )(HtmlHeadCmp),
);
