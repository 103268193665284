export interface AppSpecs {
  'specs.funnel.TemplatesGalleryEnableFilters': 'true' | 'false';
  'specs.funnel.TemplatesGalleryEnableBanner': 'true' | 'false';
  'specs.funnel.TemplatesGalleryUseReactDomHydrate': 'true' | 'false';
  'specs.funnel.TemplatesGalleryNewMobileFlow': 'true' | 'false';
  'specs.funnel.TemplatesGallerySendEmailViaBiQuery': 'true' | 'false';
  'specs.funnel.UseBusinessTermToBookCategoryMapping': 'true' | 'false';
  'specs.funnel.TemplatesGalleryReverseTemplateButtonsOrder': 'true' | 'false';
  'specs.funnel.enableSearchAssistant': 'true' | 'false';
  'specs.funnel.TemplatesGalleryEnlargedSearchInput': 'true' | 'false';
  'specs.funnel.TemplatesGalleryEnableSGBanner': 'true' | 'false';
  'specs.funnel.TemplatesGalleryDesignSiteLaterButton': 'true' | 'false';
  'specs.funnel.TemplatesGalleryEnableSeoThumbnail': 'true' | 'false';
  'specs.funnel.RecommendedTemplatesSection': 'true' | 'false';
  'specs.funnel.MarketingTemplatesMicroCategories': 'true' | 'false';
  'specs.funnel.EnableMobileSEORedirects': 'true' | 'false';
  'specs.funnel.TemplatesBulkandMicrocatsEN': 'true' | 'false';
  'specs.funnel.templateViewerV2': 'true' | 'false';
}

export const defaultAppSpecs: AppSpecs = {
  'specs.funnel.TemplatesGalleryEnableFilters': 'false',
  'specs.funnel.TemplatesGalleryEnableBanner': 'false',
  'specs.funnel.TemplatesGalleryUseReactDomHydrate': 'false',
  'specs.funnel.TemplatesGalleryNewMobileFlow': 'false',
  'specs.funnel.TemplatesGallerySendEmailViaBiQuery': 'false',
  'specs.funnel.UseBusinessTermToBookCategoryMapping': 'false',
  'specs.funnel.TemplatesGalleryReverseTemplateButtonsOrder': 'false',
  'specs.funnel.enableSearchAssistant': 'false',
  'specs.funnel.TemplatesGalleryEnlargedSearchInput': 'false',
  'specs.funnel.TemplatesGalleryEnableSGBanner': 'false',
  'specs.funnel.TemplatesGalleryDesignSiteLaterButton': 'false',
  'specs.funnel.TemplatesGalleryEnableSeoThumbnail': 'false',
  'specs.funnel.RecommendedTemplatesSection': 'false',
  'specs.funnel.MarketingTemplatesMicroCategories': 'false',
  'specs.funnel.EnableMobileSEORedirects': 'false',
  'specs.funnel.TemplatesBulkandMicrocatsEN': 'false',
  'specs.funnel.templateViewerV2': 'false',
};
