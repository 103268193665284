import React, { useMemo } from 'react';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import { OverriddenCategoryNamesStore } from '../../../../stores/OverriddenCategoryNamesStore';
import { CategoriesStore, CategoryGroup } from '../../../stores/CategoriesStore';
import { RoutingStore } from '../../../stores/RoutingStore';
import { MegaMenu } from '../../MegaMenu/MegaMenu';
import { MegaMenuDropdownItem } from '../../MegaMenu/MegaMenuDropdownSection/MegaMenuDropdownSection';
import { ExperimentsStore } from '../../../../stores/ExperimentsStore';
import { WithTranslations, withTranslations } from '../../../../utils/withTranslations';
import { Category } from '../../../../web-api/domain/category';

interface HeaderMenuProps extends WithTranslations {
  categoriesStore: CategoriesStore;
  categoryGroup: CategoryGroup;
  overriddenCategoryNamesStore: OverriddenCategoryNamesStore;
  routingStore: RoutingStore;
  experimentsStore: ExperimentsStore;
  adjustWidth?: boolean;
  setToggleRef?: (element?: HTMLDivElement) => void;
  onItemClick?: () => void;
  isRightAlignedDropdown?: boolean;
  dataHook?: string;
}

const HeaderMenuCmp: React.FC<HeaderMenuProps> = ({
  categoryGroup,
  overriddenCategoryNamesStore,
  routingStore,
  onItemClick,
  experimentsStore,
  adjustWidth,
  isRightAlignedDropdown,
  setToggleRef,
  dataHook,
  categoriesStore,
  t,
}) => {
  const isFiltersExperiment = experimentsStore.is('specs.funnel.TemplatesGalleryEnableFilters', 'true');
  const isEnabledMicroCategories =
    experimentsStore.is('specs.funnel.MarketingTemplatesMicroCategories', 'true') &&
    experimentsStore.is('specs.funnel.TemplatesBulkandMicrocatsEN', 'true');
  const { appliedFilters, activeCategorySlug, activeSubCategorySlug, activeMicroCategorySlug } = routingStore;
  const { intentCategories } = categoriesStore;

  const mapSubCategoryToMenuItem = (category: Category, subCategory: Category): MegaMenuDropdownItem => {
    const isActiveCategory = activeCategorySlug === category.name;
    const link =
      isFiltersExperiment && isActiveCategory
        ? routingStore.rebuildRoute({
            page: 1,
            categorySlug: category.name,
            subCategorySlug: subCategory.name,
            ...appliedFilters,
          })
        : routingStore.locationBuilders.subCategory({
            categorySlug: category.name,
            subCategorySlug: subCategory.name,
          });

    return {
      id: subCategory.name,
      label: overriddenCategoryNamesStore.getMenuCategoryName(subCategory),
      link,
    };
  };

  const mapCategoryToMenuItem = (category: Category): MegaMenuDropdownItem => {
    const isActiveCategory = activeCategorySlug === category.name;
    const link =
      isFiltersExperiment && isActiveCategory
        ? routingStore.rebuildRoute({
            page: 1,
            categorySlug: category.name,
            subCategorySlug: null,
            ...appliedFilters,
          })
        : routingStore.locationBuilders.category({ categorySlug: category.name });

    return {
      id: category.name,
      label: overriddenCategoryNamesStore.getMenuCategoryName(category),
      link,
      items: category.subCategories.map((subCategory) => mapSubCategoryToMenuItem(category, subCategory)),
    };
  };

  const label = t(categoryGroup.displayNameKey);
  const shortLabel = t(categoryGroup?.shortDisplayNameKey) || label;
  const items = categoryGroup.categories.filter((category) => !!category).map(mapCategoryToMenuItem);
  const activeCategoryName = activeSubCategorySlug || activeCategorySlug || activeMicroCategorySlug;
  const intCategories = intentCategories?.filter(({ subCategories }) =>
    subCategories.find(({ name }) => name === activeMicroCategorySlug),
  );
  const isMenuActive = useMemo(
    () =>
      intCategories?.some(({ name }) =>
        items.some(({ id, items }) => id === name || items?.some(({ id }) => id === name)),
      ),
    [intCategories, items],
  );

  return (
    <MegaMenu
      label={label}
      items={items}
      shortLabel={shortLabel}
      activeItemId={activeCategoryName}
      onItemClick={onItemClick}
      adjustWidth={adjustWidth}
      isRightAlignedDropdown={isRightAlignedDropdown}
      dataHook={dataHook}
      setToggleRef={setToggleRef}
      isMenuActive={isMenuActive && isEnabledMicroCategories}
    />
  );
};

export const HeaderMenu = withTranslations(
  injectStoresV2('overriddenCategoryNamesStore', 'routingStore', 'experimentsStore', 'categoriesStore')(HeaderMenuCmp),
);
