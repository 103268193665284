import React from 'react';
import { injectStoresV2 } from '../../stores/injectStoresV2';
import PaginationLast from '../../../Icons/PaginationLast.svg';
import PaginationNext from '../../../Icons/PaginationNext.svg';
import { BILoggerStore } from '../../stores/BILoggerStore';
import { TemplatesStore } from '../../stores/TemplatesStore';
import { RoutingStore } from '../../stores/RoutingStore';
import { useScrollToTop } from '../../contexts/ScrollToTopContext';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import { HeaderStore } from '../../stores/HeaderStore';
import { numericPaginationDataHooks } from './NumericPagination.dataHooks';
import { PageLink } from './PageLink';
import s from './NumericPagination.scss';

const MAX_SEQUENCE = 5;

interface NumericPaginationProps extends WithTranslations {
  dataHook?: string;
  templatesStore: TemplatesStore;
  routingStore: RoutingStore;
  biLoggerStore: BILoggerStore;
  headerStore: HeaderStore;
}

const NumericPaginationCmp: React.FC<NumericPaginationProps> = (props) => {
  const { routingStore, templatesStore, biLoggerStore, t, dataHook, headerStore } = props;
  const { setIsRecomendedSctionOpened } = headerStore;
  const { scrollToTop } = useScrollToTop();

  const handlePageClick = (page: number) => {
    setIsRecomendedSctionOpened(false);
    scrollToTop();
    biLoggerStore.logPagination(page);
  };

  const { totalPages } = templatesStore;
  const { currentPage } = routingStore;

  if (totalPages <= 1) {
    return <div className={s.spacer} data-hook={numericPaginationDataHooks.spacer()} />;
  }
  const firstPageIndex = 1;
  const currentPageIndex = currentPage - firstPageIndex;
  const sequence = Array(totalPages)
    .fill(null)
    .map((_, index) => index + 1)
    .slice(Math.max(currentPageIndex - 2, 0), Math.max(currentPageIndex + 3, MAX_SEQUENCE));

  const previousPage = Math.max(currentPage - firstPageIndex, firstPageIndex);
  const nextPage = Math.min(currentPage + firstPageIndex, totalPages);
  const pagesLength = sequence[sequence.length - firstPageIndex];

  return (
    <nav data-hook={dataHook} className={s.pagination} aria-label={t('pagination.label')}>
      <ul className={s.list}>
        <PageLink
          dataHook={numericPaginationDataHooks.firstArrowLink()}
          onPageClick={() => handlePageClick(firstPageIndex)}
          title={t('pagination.toFirst')}
          className={s.firstArrow}
          page={firstPageIndex}
        >
          <PaginationLast fill="currentColor" width="1em" height="1em" className={s.paginationFirst} />
        </PageLink>
        <PageLink
          dataHook={numericPaginationDataHooks.previousArrowLink()}
          onPageClick={() => handlePageClick(previousPage)}
          className={s.previousArrow}
          page={previousPage}
          title={t('pagination.prev')}
        >
          <PaginationNext fill="currentColor" width="1em" height="1em" className={s.paginationPrevious} />
        </PageLink>
        {sequence.map((pageNumber) => (
          <PageLink
            key={pageNumber}
            dataHook={numericPaginationDataHooks.pageLink({ page: pageNumber })}
            onPageClick={() => handlePageClick(pageNumber)}
            className={s.page}
            page={pageNumber}
            title={t('pagination.page.title', { page: pageNumber })}
          >
            <span aria-hidden="true">{pageNumber}</span>
          </PageLink>
        ))}
        {totalPages - firstPageIndex > pagesLength && (
          <li data-hook={numericPaginationDataHooks.dots()} className={s.dots}>
            ...
          </li>
        )}
        {totalPages > pagesLength && (
          <PageLink
            dataHook={numericPaginationDataHooks.pageLink({ page: totalPages })}
            onPageClick={() => handlePageClick(totalPages)}
            title={t('pagination.page.title', { page: totalPages })}
            className={s.page}
            page={totalPages}
          >
            <span aria-hidden="true">{totalPages}</span>
          </PageLink>
        )}
        <PageLink
          dataHook={numericPaginationDataHooks.nextArrowLink()}
          onPageClick={() => handlePageClick(nextPage)}
          className={s.nextArrow}
          title={t('pagination.next')}
          page={nextPage}
        >
          <PaginationNext fill="currentColor" width="1em" height="1em" />
        </PageLink>
        <PageLink
          dataHook={numericPaginationDataHooks.lastArrowLink()}
          onPageClick={() => handlePageClick(totalPages)}
          className={s.lastArrow}
          title={t('pagination.toLast')}
          page={totalPages}
        >
          <PaginationLast fill="currentColor" width="1em" height="1em" />
        </PageLink>
      </ul>
    </nav>
  );
};

export const NumericPagination = withTranslations(
  injectStoresV2('templatesStore', 'routingStore', 'biLoggerStore', 'headerStore')(NumericPaginationCmp),
);
