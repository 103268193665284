import { generatePath, matchPath } from 'react-router';
import { buildSortFilterSearchQuery, parseSortFiltersSearchQuery, SortFiltersParams } from './sortFiltersParams';
import { createRoute } from './createRoute';

export interface MicroCategoryPageParams extends SortFiltersParams {
  page: number;
  microCategorySlug: string;
}

export interface MicroCategoryPage {
  routeName: 'microCategory';
  params: MicroCategoryPageParams;
}

export const microCategoryRoute = createRoute<MicroCategoryPageParams, 'microCategorySlug'>({
  pattern: '/refine/:microCategorySlug/:page(\\d+)?',
  build: (pattern, params) => {
    const { page, microCategorySlug } = params;

    const pathParams = {
      page: page === 1 ? undefined : page,
      microCategorySlug: (microCategorySlug || '').toLowerCase(),
    };

    const searchQuery = buildSortFilterSearchQuery(params);
    return {
      pathname: generatePath(pattern, pathParams),
      search: searchQuery,
    };
  },
  match: (pattern, location) => {
    const { pathname, search } = location;
    const match = matchPath<{ microCategorySlug: string; page: string }>(pathname, {
      path: pattern,
      exact: true,
      sensitive: false,
    });

    if (!match) {
      return null;
    }

    const requestedPage = Number(match.params.page);
    return {
      ...parseSortFiltersSearchQuery(search),
      microCategorySlug: decodeURIComponent(match.params.microCategorySlug ?? ''),
      page: requestedPage || 1,
    };
  },
});
