import React, { FC } from 'react';
import cs from 'classnames';
import { SeoLink } from '../SeoLink/SeoLink';
import { RouteLocation } from '../../stores/routes/createRoute';

import s from '../../../common/styles/TagLink.scss';

type TagLinkProps = {
  location: RouteLocation;
  title: string;
  order?: number;
  dataHookLink?: string;
  dataHook?: string;
  isActive?: boolean;
  onClick?: VoidFunction;
};

export const TagLink: FC<TagLinkProps> = ({ onClick, isActive, dataHook, title, location }) => {
  return (
    <SeoLink
      className={cs(s.tagLink, { [s.active]: isActive })}
      aria-selected={isActive}
      to={location}
      onClick={onClick}
      dataHook={dataHook}
    >
      {title}
    </SeoLink>
  );
};
