import React, { FC, useCallback, useMemo } from 'react';

import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import { injectStoresV2 } from '../../stores/injectStoresV2';
import { RoutingStore } from '../../stores/RoutingStore';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { TagLink } from '../TagLink/TagLink';
import { BILoggerStore } from '../../stores/BILoggerStore';
import { useScrollToTop } from '../../contexts/ScrollToTopContext';
import styles from './MicroCategoriesTags.scss';
import { microCategoriesDataHooks } from './MicroCategoriesTags.dataHook';

export type MicroCategoriesTagsProps = {
  categoriesStore: CategoriesStore;
  routingStore: RoutingStore;
  biLoggerStore: BILoggerStore;
} & WithTranslations;

const MicroCategoriesTagsCmp: FC<MicroCategoriesTagsProps> = (props) => {
  const { scrollToTop } = useScrollToTop();
  const {
    t,
    biLoggerStore,
    routingStore: { locationBuilders, activeCategorySlug, activeSubCategorySlug },
    categoriesStore: { intentCategories },
  } = props;

  const microCategories = useMemo(
    () =>
      (intentCategories ?? []).find(({ name }) => name === (activeSubCategorySlug || activeCategorySlug))
        ?.subCategories ?? [],
    [activeCategorySlug, activeSubCategorySlug, intentCategories],
  );

  const onHandleBI = useCallback(
    ({ microCategory, selectedValue }) => {
      biLoggerStore.logMicroCategoryClick({
        microCategory,
        selectedValue,
        subCategory: activeSubCategorySlug,
        category: activeCategorySlug,
      });
    },
    [biLoggerStore, activeSubCategorySlug, activeCategorySlug],
  );

  if (!microCategories?.length) {
    return null;
  }

  return (
    <div className={styles.MicroCategoriesTags} data-hook={microCategoriesDataHooks.container()}>
      <span className={styles.MicroCategoriesTags__title}>{t('microCategories.title')}</span>
      <div className={styles.MicroCategoriesTags__tasList}>
        {microCategories?.map(({ displayName, categoryId, name }) => {
          return (
            <TagLink
              dataHook={microCategoriesDataHooks.tagLink()}
              title={displayName}
              key={categoryId}
              onClick={() => {
                scrollToTop();
                onHandleBI({ microCategory: name, selectedValue: displayName });
              }}
              location={locationBuilders.microCategory({
                microCategorySlug: name,
              })}
            />
          );
        })}
      </div>
    </div>
  );
};

export const MicroCategoriesTags = withTranslations(
  injectStoresV2('categoriesStore', 'routingStore', 'biLoggerStore')(MicroCategoriesTagsCmp),
);
