import React from 'react';
import cs from 'classnames';

import DropdownArrow from '../../../../Icons/DropdownArrow.svg';
import ChevronBlueUp from '../../../../Icons/ChevronBlueUpCrop.svg';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { megaMenuToggleDataHooks } from './MegaMenuToggle.dataHooks';

import styles from './MegaMenuToggle.scss';

interface MegaMenuToggleProps {
  label: string;
  shortLabel: string;
  onFocus: (event: React.FocusEvent) => void;
  isMenuOpen: boolean;
  isActive: boolean;
  dataHook?: string;
  isArrowToTop?: boolean;
}

export const MegaMenuToggle = React.forwardRef<HTMLDivElement, MegaMenuToggleProps>(
  ({ label, onFocus, shortLabel, isMenuOpen, isActive, dataHook, isArrowToTop }, ref) => {
    const isMounted = useIsMounted();

    const toggleClassName = cs(styles.toggle, {
      [styles.toggleMenuActive]: isActive,
      [styles.toggleMenuOpen]: isMenuOpen,
      [styles.toggleMenuNoJsFallback]: !isMounted,
    });

    return (
      <div
        className={toggleClassName}
        onFocus={onFocus}
        data-hook={dataHook}
        tabIndex={0}
        ref={ref}
        data-bi-element="category_bucket"
        data-bi-element-value={label}
      >
        <span className={styles.labelWrapper} tabIndex={-1}>
          <span className={styles.label} aria-hidden="false" data-hook={megaMenuToggleDataHooks.label()}>
            {label}
          </span>
          <span
            className={cs(styles.label, styles.shortLabel)}
            aria-hidden="true"
            data-hook={megaMenuToggleDataHooks.shortLabel()}
          >
            {shortLabel}
          </span>
          {isArrowToTop ? (
            <ChevronBlueUp fill="currentColor" height="1em" className={styles.arrow} />
          ) : (
            <DropdownArrow fill="currentColor" height="1em" className={styles.arrow} />
          )}
        </span>
      </div>
    );
  },
);
