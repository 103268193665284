import React, { useMemo } from 'react';
import { injectStoresV2 } from '../../stores/injectStoresV2';
import { TemplatesStore } from '../../stores/TemplatesStore';
import { Sort } from '../Sort/Sort';
import { RoutingStore } from '../../stores/RoutingStore';
import { ConfigStore } from '../../stores/ConfigStore';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { getBreadcrumbsPath } from '../../../utils/getBreadcrumbs';
import { ExperimentsStore } from '../../../stores/ExperimentsStore';
import { SubTitle } from './SubTitle/SubTitle';
import { SubText } from './SubText/SubText';
import { SubHeaderBreadcrumbs } from './SubHeaderBreadcrumbs/SubHeaderBreadcrumbs';
import { SubCategories } from './SubCategories/SubCategories';
import { subHeaderDataHooks } from './SubHeader.dataHooks';
import s from './SubHeader.scss';

export interface SubHeaderProps {
  configStore: ConfigStore;
  routingStore: RoutingStore;
  templatesStore: TemplatesStore;
  categoriesStore: CategoriesStore;
  experimentsStore: ExperimentsStore;
  dataHook?: string;
}

const SubHeaderCmp: React.FC<SubHeaderProps> = (props) => {
  const { dataHook, configStore, routingStore, templatesStore, experimentsStore } = props;
  const { activeSubCategory, activeCategory } = templatesStore;
  const { activeMicroCategorySlug } = routingStore;
  const isEnabledMicroCategories =
    experimentsStore.is('specs.funnel.MarketingTemplatesMicroCategories', 'true') &&
    experimentsStore.is('specs.funnel.TemplatesBulkandMicrocatsEN', 'true');

  const breadcrumbs = getBreadcrumbsPath(props);

  const isSortingEnabled = useMemo(() => {
    if (configStore.config.currentLanguage !== 'en') {
      return false;
    }

    if (activeCategory && activeCategory.type === 'main' && activeCategory.name !== 'all') {
      return false;
    }

    if (!!routingStore.activeSearchCriteria && templatesStore.isNoResultSuggestions) {
      return false;
    }

    return true;
  }, [
    activeCategory,
    configStore.config.currentLanguage,
    routingStore.activeSearchCriteria,
    templatesStore.isNoResultSuggestions,
  ]);

  const isAllTemplates = activeCategory?.name === 'all';

  const hasActiveSubCategory = useMemo(
    () =>
      isEnabledMicroCategories
        ? Boolean(activeSubCategory || activeMicroCategorySlug || activeCategory) && !isAllTemplates
        : Boolean(activeSubCategory),
    [activeCategory, activeMicroCategorySlug, activeSubCategory, isAllTemplates, isEnabledMicroCategories],
  );

  return (
    <div className={hasActiveSubCategory ? s.subHeader : s.subHeaderWithTopMargin} data-hook={dataHook}>
      {hasActiveSubCategory && !isAllTemplates && (
        <SubHeaderBreadcrumbs dataHook={subHeaderDataHooks.breadcrumbs()} breadcrumbs={breadcrumbs} />
      )}
      <div className={s.subTitleSortWrapper}>
        <SubTitle dataHook={subHeaderDataHooks.subTitle()} />
        {isSortingEnabled && <Sort dataHook={subHeaderDataHooks.sortMenu()} />}
      </div>
      <SubText dataHook={subHeaderDataHooks.subText()} />
      <SubCategories dataHook={subHeaderDataHooks.subCategories()} />
    </div>
  );
};

export const SubHeader = injectStoresV2(
  'configStore',
  'routingStore',
  'templatesStore',
  'categoriesStore',
  'experimentsStore',
)(SubHeaderCmp);
