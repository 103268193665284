import React from 'react';
import { SeoLink } from '../../SeoLink/SeoLink';
import { Category } from '../../../../web-api/domain/category';
import { RouteLocation } from '../../../stores/routes/createRoute';
import { ExperimentsStore } from '../../../../stores/ExperimentsStore';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import { CategoriesStore } from '../../../stores/CategoriesStore';
import { TemplatesStore } from '../../../stores/TemplatesStore';
import { RoutingStore } from '../../../stores/RoutingStore';
import { WithTranslations, withTranslations } from '../../../../utils/withTranslations';
import { subHeaderBreadcrumbsDataHooks } from './SubHeaderBreadcrumbs.dataHooks';
import s from './SubHeaderBreadcrumbs.scss';

interface SubHeaderBreadCrumbsProps extends WithTranslations {
  dataHook?: string;
  breadcrumbs: { category: Category; url: RouteLocation }[];
  experimentsStore: ExperimentsStore;
  categoriesStore: CategoriesStore;
  templatesStore: TemplatesStore;
  routingStore: RoutingStore;
}

export const SubHeaderBreadCrumbsCmp: React.FC<SubHeaderBreadCrumbsProps> = (props) => {
  const { dataHook, breadcrumbs, experimentsStore, categoriesStore, templatesStore, routingStore, t } = props;
  const isEnabledMicroCategories =
    experimentsStore.is('specs.funnel.MarketingTemplatesMicroCategories', 'true') &&
    experimentsStore.is('specs.funnel.TemplatesBulkandMicrocatsEN', 'true');

  if (!isEnabledMicroCategories) {
    const category = categoriesStore.getCategoryBySlug(templatesStore.fetchParams?.categorySlug);
    if (!category) {
      return null;
    }

    const categoryUrl =
      category &&
      routingStore.rebuildRoute({
        page: 1,
        categorySlug: category.name,
        subCategorySlug: null,
      });
    return (
      <div className={s.root} data-hook={dataHook}>
        <SeoLink
          className={s.category}
          to={categoryUrl}
          dataHook={subHeaderBreadcrumbsDataHooks.categoryLink()}
          data-bi-element="breadcrumb"
          data-bi-element-value="breadcrumb"
          data-bi-gallery-doc-index="0"
        >
          {category.displayName}
        </SeoLink>
      </div>
    );
  }

  return (
    <div className={s.root} data-hook={dataHook}>
      <SeoLink
        className={s.category}
        to={routingStore.locationBuilders.home({})}
        dataHook={subHeaderBreadcrumbsDataHooks.categoryLink()}
        data-bi-element="breadcrumb"
        data-bi-element-value="breadcrumb"
        data-bi-gallery-doc-index="0"
      >
        {t('breadcrumbs.templates')}
      </SeoLink>
      {breadcrumbs.map(({ category, url }) => (
        <SeoLink
          key={category.categoryId}
          className={s.category}
          to={url}
          dataHook={subHeaderBreadcrumbsDataHooks.categoryLink()}
          data-bi-element="breadcrumb"
          data-bi-element-value="breadcrumb"
          data-bi-gallery-doc-index="0"
        >
          {category.displayName}
        </SeoLink>
      ))}
    </div>
  );
};

// TODO:remove when specs.funnel.MarketingTemplatesMicroCategories be merged
export const SubHeaderBreadcrumbs = withTranslations(
  injectStoresV2('experimentsStore', 'routingStore', 'categoriesStore', 'templatesStore')(SubHeaderBreadCrumbsCmp),
);
