import { Category } from '../web-api/domain/category';
import { RoutingStore } from '../v2/stores/RoutingStore';
import { CategoriesStore } from '../v2/stores/CategoriesStore';

export const getBreadcrumbs = ({
  categories,
  destinationCategorySlug,
}: {
  categories?: Category[];
  destinationCategorySlug?: string;
}): Category[] => {
  if (!destinationCategorySlug || !categories?.length) {
    return [];
  }
  let longestBreadcrumbs = [];

  categories.forEach((category) => {
    const { subCategories } = category;
    let currentBreadcrumbs = [];

    if (subCategories?.length) {
      const subBreadcrumbs = getBreadcrumbs({ categories: subCategories, destinationCategorySlug });
      if (subBreadcrumbs.length) {
        currentBreadcrumbs = [category, ...subBreadcrumbs];
      }
    }

    if (category.name === destinationCategorySlug) {
      currentBreadcrumbs = [category];
    }

    if (currentBreadcrumbs.length > longestBreadcrumbs.length) {
      longestBreadcrumbs = currentBreadcrumbs;
    }
  });

  return longestBreadcrumbs;
};

export const getBreadcrumbsPath = ({
  routingStore,
  categoriesStore,
}: {
  routingStore: RoutingStore;
  categoriesStore: CategoriesStore;
}) => {
  const { activeMicroCategorySlug, activeSubCategorySlug } = routingStore;
  const { intentCategories, categories } = categoriesStore;

  const intCategories = intentCategories?.filter(({ subCategories }) =>
    subCategories.find(({ name }) => name === activeMicroCategorySlug),
  );

  if (!intCategories && !activeSubCategorySlug) {
    return [];
  }

  let breadcrumbsList = [];
  // we have the deepest chain of category and subCategory
  if (intCategories.length) {
    const breadcrumbs = intCategories.map(({ name }) =>
      getBreadcrumbs({
        destinationCategorySlug: name,
        categories,
      }),
    );
    breadcrumbsList = breadcrumbs.reduce((acc, curr) => (acc.length > curr.length ? acc : curr), []);
  } else {
    // SubCategory case
    breadcrumbsList = getBreadcrumbs({
      categories,
      destinationCategorySlug: activeSubCategorySlug,
    });
  }

  if (!activeMicroCategorySlug) {
    breadcrumbsList = breadcrumbsList.filter(({ name }) => name !== activeSubCategorySlug);
  }

  const [category, subCategory] = breadcrumbsList;

  return [
    ...(category
      ? [
          {
            category,
            url: routingStore.rebuildRoute({
              categorySlug: category?.name,
              subCategorySlug: null,
              microCategorySlug: null,
            }),
          },
        ]
      : []),
    ...(subCategory
      ? [
          {
            category: subCategory,
            url: routingStore.rebuildRoute({
              categorySlug: category?.name,
              subCategorySlug: subCategory?.name,
              microCategorySlug: null,
            }),
          },
        ]
      : []),
  ];
};
