import React from 'react';
import { useIsMounted } from '../../hooks/useIsMounted';
import { MegaMenuDropdown } from './MegaMenuDropdown/MegaMenuDropdown';
import { MegaMenuToggle } from './MegaMenuToggle/MegaMenuToggle';
import { MegaMenuDropdownItem } from './MegaMenuDropdownSection/MegaMenuDropdownSection';
import { megaMenuDataHooks } from './MegaMenu.dataHooks';
import styles from './MegaMenu.scss';
import { SimpleMenuDropdown } from './SimpleMenuDropdown/SimpleMenuDropdown';

interface MegaMenuProps {
  label: string;
  shortLabel?: string;
  items: MegaMenuDropdownItem[];
  activeItemId?: string;
  onItemClick?: () => void;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  adjustWidth?: boolean;
  dataHook?: string;
  setToggleRef?: (element?: HTMLDivElement) => void;
  isRightAlignedDropdown?: boolean;
  isMenuActive?: boolean;
}

const DROPDOWN_OPEN_CLOSE_DELAY = 200;

export const MegaMenu: React.FC<MegaMenuProps> = (props) => {
  const {
    label,
    shortLabel = label,
    items,
    activeItemId,
    onItemClick,
    onMenuOpen,
    onMenuClose,
    adjustWidth,
    dataHook,
    isRightAlignedDropdown,
  } = props;
  const [isMenuOpen, setMenuOpenState] = React.useState<boolean>(false);
  const isMounted = useIsMounted();

  const closeMenu = () => {
    onMenuClose && onMenuClose();
    setMenuOpenState(false);
  };

  const openMenu = () => {
    onMenuOpen && onMenuOpen();
    setMenuOpenState(true);
  };

  const handleItemClick = () => {
    onItemClick && onItemClick();
    closeMenu();
  };
  const isMenuActive =
    items.some(
      ({ id: topLevelId, items }) => activeItemId === topLevelId || (items || []).some(({ id }) => activeItemId === id),
    ) || props.isMenuActive;

  const closeTimeoutId = React.useRef<number | null>(null);
  const openTimeoutId = React.useRef<number | null>(null);

  React.useEffect(() => {
    return () => {
      clearTimeout(openTimeoutId.current);
      clearTimeout(closeTimeoutId.current);
    };
  }, []);

  const closeMenuWithDelay = () => {
    clearTimeout(openTimeoutId.current);
    closeTimeoutId.current = window.setTimeout(closeMenu, DROPDOWN_OPEN_CLOSE_DELAY);
  };

  const openMenuWithDelay = () => {
    clearTimeout(closeTimeoutId.current);
    openTimeoutId.current = window.setTimeout(openMenu, DROPDOWN_OPEN_CLOSE_DELAY);
  };

  const containerRef = React.useRef<HTMLLIElement>(null);
  const handleContainerFocusOut = ({ relatedTarget }: React.FocusEvent<HTMLLIElement>) => {
    if (relatedTarget === null || (relatedTarget instanceof Node && !containerRef.current.contains(relatedTarget))) {
      closeMenuWithDelay();
    }
  };

  const toggleRef = React.useRef<HTMLDivElement>(null);
  const setToggleRef = (element: HTMLDivElement) => {
    toggleRef.current = element;
    props.setToggleRef && props.setToggleRef(element);
  };

  const isClosedByEscKey = React.useRef<boolean>(false);
  const handleToggleFocus = () => {
    if (!isClosedByEscKey.current) {
      openMenuWithDelay();
      return;
    }

    isClosedByEscKey.current = false;
  };
  const handleContainerKeyDown = ({ key }: React.KeyboardEvent<HTMLLIElement>) => {
    if (key !== 'Escape') {
      return;
    }

    if (toggleRef.current !== document.activeElement) {
      isClosedByEscKey.current = true;
      toggleRef.current.focus();
    }

    closeMenu();
  };

  const isSimpleMenu = items.every(({ items }) => !items?.length);
  const isOpen = !isMounted || isMenuOpen;
  const dropdownNoJsFallbackClassName = !isMounted ? styles.dropdownNoJsFallback : undefined;

  return (
    <li
      className={styles.menuContainer}
      onMouseEnter={openMenuWithDelay}
      onMouseLeave={closeMenuWithDelay}
      onBlur={handleContainerFocusOut}
      onKeyDown={handleContainerKeyDown}
      ref={containerRef}
      data-hook={dataHook}
    >
      <MegaMenuToggle
        label={label}
        isMenuOpen={isMenuOpen}
        isActive={isMenuActive}
        shortLabel={shortLabel}
        onFocus={handleToggleFocus}
        dataHook={megaMenuDataHooks.toggle()}
        ref={setToggleRef}
      />
      {isSimpleMenu ? (
        <SimpleMenuDropdown
          isOpen={isOpen}
          items={items}
          onItemClick={handleItemClick}
          className={dropdownNoJsFallbackClassName}
          isRightAligned={isRightAlignedDropdown}
          dataHook={megaMenuDataHooks.simpleMenuDropdown()}
        />
      ) : (
        <MegaMenuDropdown
          isOpen={isOpen}
          items={items}
          groupLabel={label}
          onItemClick={handleItemClick}
          adjustWidth={adjustWidth}
          className={dropdownNoJsFallbackClassName}
          dataHook={megaMenuDataHooks.megaMenuDropdown()}
        />
      )}
    </li>
  );
};
