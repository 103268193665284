import { CategoriesStore } from '../../stores/CategoriesStore';
import { RoutingStore } from '../../stores/RoutingStore';
import { OverriddenCategoryNamesStore } from '../../../stores/OverriddenCategoryNamesStore';
import { TFunction } from '../../../i18n/i18n';

interface GenerateDescriptionParams {
  routingStore: RoutingStore;
  categoriesStore: CategoriesStore;
  overriddenCategoryNamesStore: OverriddenCategoryNamesStore;
  t: TFunction;
}

export function generateDescription({
  routingStore,
  categoriesStore,
  overriddenCategoryNamesStore,
  t,
}: GenerateDescriptionParams): string {
  const { matchedRoute, primaryCategorySlug } = routingStore;

  if (matchedRoute.routeName === 'microCategory') {
    const category = categoriesStore.getIntentCategoryBySlug(matchedRoute.params.microCategorySlug);
    const categoryName = overriddenCategoryNamesStore.getPageTitleCategoryName(category);

    return t('seo.description.category.v2', { category: categoryName });
  }

  if (
    matchedRoute.routeName === 'home' ||
    (matchedRoute.routeName === 'category' && matchedRoute.params.categorySlug === primaryCategorySlug)
  ) {
    return t('seo.description.home');
  }

  if (matchedRoute.routeName === 'category') {
    const category = categoriesStore.getCategoryBySlug(matchedRoute.params.categorySlug);
    const categoryName = overriddenCategoryNamesStore.getPageTitleCategoryName(category);

    return t('seo.description.category.v2', { category: categoryName });
  }

  if (matchedRoute.routeName === 'subCategory') {
    const subCategory = categoriesStore.getCategoryBySlug(matchedRoute.params.subCategorySlug);

    const subCategoryName = overriddenCategoryNamesStore.getPageTitleCategoryName(subCategory);

    return t('seo.description.category.v2', { category: subCategoryName });
  }

  return '';
}
